import { environment } from './../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {ServiciosMovimientos} from  './../models/servicios-movimientos';
import {Facturas} from  './../models/facturas';

@Injectable({
  providedIn: 'root'
})
export class MovimientosService {

  constructor(private http: HttpClient) { }

   public movimientos(id: number): Observable<ServiciosMovimientos[]>{
    return this.http.get<ServiciosMovimientos[]>(environment.global + `alpha/obtenerMovimientos/${id}`);
  }
  consultarRecibo(folio:string,usuario:number) {
    const datos = { recibo: folio,
    usuario:usuario};
    const options = {
       headers: {
        'Content-Type': 'application/json',
        //'responseType': 'text/plain;charset=UTF-8'
      }
    };
    return this.http.post(environment.facturacion+'alpha/facturarRecibo', JSON.stringify(datos), options);  //.toPromise()
  }
  facturar(data:any) {

    const options = {
       headers: {
        'Content-Type': 'application/json',
        //'responseType': 'text/plain;charset=UTF-8'
      }
    };
    return this.http.post(environment.facturacion+'alpha/facturacion', JSON.stringify(data), options);  //.toPromise()
  }
  public facturas(id: number): Observable<Facturas[]>{
    return this.http.get<Facturas[]>(environment.global + `alpha/clienteFactura/${id}`);
  }
  descargarFactura(uuid:string,formato:string) {
    const datos = { idFactura: uuid,
    formato:formato,
  type:'issued'};
    const options = {
       headers: {
        'Content-Type': 'application/json',
        //'responseType': 'text/plain;charset=UTF-8'
      }
    };
    return this.http.post(environment.facturacion+'alpha/descargarFactura', JSON.stringify(datos), options);  //.toPromise()
  }
}
