import { SettingsService } from './servicios/settings.service';
import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private settings: SettingsService) {
    if(environment.club === 'alpha'){
      settings.enableAlpha();
    }
    if(environment.club === 'sports'){
      settings.enableSportsPlaza();
    }
    if(environment.club === 'cimera'){
      settings.enableCimera();
    }
  }
}
