import { FormularioFacturacionComponent } from './formulario-facturacion/formulario-facturacion.component';
import { ModalImageComponent } from './modal-image/modal-image.component';
import { OxxoPayComponent } from './oxxo-pay/oxxo-pay.component';
import { ModalRankingComponent } from './modal-ranking/modal-ranking.component';
import { CustomCard1Component } from './custom-card1/custom-card1.component';
import { CustomSkeletonFreshComponent } from './custom-skeleton-fresh/custom-skeleton-fresh.component';
import { CustomListToggleComponent } from './custom-list-toggle/custom-list-toggle.component';
import { CustomAnimationList1Component } from './custom-animation-list1/custom-animation-list1.component';
import { CustomAnimationList6Component } from './custom-animation-list6/custom-animation-list6.component';
import { CustomSkeletonImgComponent } from './custom-skeleton-img/custom-skeleton-img.component';
import { CustomSkeletonCard3Component } from './custom-skeleton-card3/custom-skeleton-card3.component';
import { CustomCard3Component } from './custom-card3/custom-card3.component';
import { CustomPaginationComponent } from './custom-pagination/custom-pagination.component';
import { CustomProductListComponent } from './custom-product-list/custom-product-list.component';
import { CustomNewsComponent } from './custom-news/custom-news.component';
import { CustomProfile2Component } from './custom-profile2/custom-profile2.component';
import { CustomSkeletonProfile2Component } from './custom-skeleton-profile2/custom-skeleton-profile2.component';
import { CustomSlidesComponent } from './custom-slides/custom-slides.component';
import { CustomHeaderComponent } from './custom-header/custom-header.component';
import { CustomSideRightComponent } from './custom-side-right/custom-side-right.component';
import { RouterModule } from '@angular/router';
import { CustomSideMenuComponent } from './custom-side-menu/custom-side-menu.component';
import { CustomLoginComponent } from './custom-login/custom-login.component';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { SpinnerComponent } from './spinner/spinner.component';



@NgModule({
  declarations: [
    CustomLoginComponent,
    CustomSideMenuComponent,
    CustomSideRightComponent,
    CustomHeaderComponent,
    CustomSlidesComponent,
    CustomSkeletonProfile2Component,
    CustomProfile2Component,
    CustomNewsComponent,
    CustomProductListComponent,
    CustomPaginationComponent,
    CustomCard3Component,
    CustomSkeletonCard3Component,
    CustomSkeletonImgComponent,
    CustomAnimationList6Component,
    CustomAnimationList1Component,
    CustomListToggleComponent,
    CustomSkeletonFreshComponent,
    CustomCard1Component,
    ModalRankingComponent,
    SpinnerComponent,
    OxxoPayComponent,
    ModalImageComponent,
    FormularioFacturacionComponent,
  ],
  exports:[
    CustomLoginComponent,
    CustomSideMenuComponent,
    CustomSideRightComponent,
    CustomHeaderComponent,
    CustomSlidesComponent,
    CustomSkeletonProfile2Component,
    CustomProfile2Component,
    CustomNewsComponent,
    CustomProductListComponent,
    CustomPaginationComponent,
    CustomCard3Component,
    CustomSkeletonCard3Component,
    CustomAnimationList6Component,
    CustomListToggleComponent,
    CustomSkeletonFreshComponent,
    CustomCard1Component,
    ModalRankingComponent,
    SpinnerComponent,
    OxxoPayComponent,
    ModalImageComponent,
    FormularioFacturacionComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    IonicModule.forRoot(),
    NgxQRCodeModule,
  ]
})
export class ComponentsModule { }
