import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  renderer: Renderer2;
  constructor(private rendererFactory: RendererFactory2, @Inject(DOCUMENT) private document: Document) {
    this.renderer = this.rendererFactory.createRenderer(null,null);
   }

  enableAlpha(){
    this.renderer.removeClass(this.document.body, 'sports-theme');
    this.renderer.addClass(this.document.body, 'alpha-theme');
  }

  enableSportsPlaza(){
    this.renderer.removeClass(this.document.body, 'alpha-theme');
    this.renderer.addClass(this.document.body, 'sports-theme');
  }

  enableCimera(){
    this.renderer.removeClass(this.document.body, 'alpha-theme');
    this.renderer.removeClass(this.document.body, 'sports-theme');
    this.renderer.addClass(this.document.body, 'cimera-theme');
  }
  
}
